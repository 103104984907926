import yup from "../helpers/customYup";

export interface PricingPrefixT {
  value: number;
  prefix: "%" | "€";
}

export interface CategoryMarginAndDiscountT {
  margin: CategoryMarginPrefixT;
  discount: CategoryDiscountPrefixT;
}

export interface CategoryMarginPrefixT {
  registeredCustomerMargin: PricingPrefixT;
  unregisteredCustomerMargin: PricingPrefixT;
  wholesaleCustomerMargin: PricingPrefixT;
}

export interface CategoryDiscountPrefixT {
  registeredCustomerDiscount: PricingPrefixT;
  unregisteredCustomerDiscount: PricingPrefixT;
  wholesaleCustomerDiscount: PricingPrefixT;
}

export const porezProductCategoryTypes = ["board", "abs"] as const;
export type porezProductCategoryTypeT = typeof porezProductCategoryTypes[number];

export interface BaseVariobelProductCategoryT {
  name: string;
  parentCategory: string | null; // _id of another product category
  discount: CategoryDiscountPrefixT;
  margin: CategoryMarginPrefixT;
  applyMarginToChildrenCategories: boolean;
}

export interface BasePorezProductCategoryT extends BaseVariobelProductCategoryT {
  type: porezProductCategoryTypeT
}

export type BaseProductCategoryT = BaseVariobelProductCategoryT | BasePorezProductCategoryT;

const pricingPrefixYupT = yup
  .object()
  .shape({
    value: yup.number().required(),
    prefix: yup.string().oneOf(["%", "€"]).required(),
  })
  .required();

const marginPrefixYupT = yup.object().shape({
  registeredCustomerMargin: pricingPrefixYupT,
  unregisteredCustomerMargin: pricingPrefixYupT,
  wholesaleCustomerMargin: pricingPrefixYupT,
});

const discountPrefixYupT = yup.object().shape({
  registeredCustomerDiscount: pricingPrefixYupT,
  unregisteredCustomerDiscount: pricingPrefixYupT,
  wholesaleCustomerDiscount: pricingPrefixYupT,
});

const mutualSchema = {
  name: yup.string().required(),
  parentCategory: yup.string().nullable(),
  discount: discountPrefixYupT,
  margin: marginPrefixYupT,
  applyMarginToChildrenCategories: yup.boolean().required(),
}

export const BaseProductCategoryValidationSchema = yup.object().shape({
  ...mutualSchema
});

export const PorezProductCategoryValidationSchema = yup.object().shape({
  ...mutualSchema,
  type: yup.string().oneOf(porezProductCategoryTypes).required(),
})

export interface VariobelProductCategoryT extends BaseVariobelProductCategoryT {
  _id: string;
}

export interface PorezProductCategoryT extends BasePorezProductCategoryT {
  _id: string;
}

export type ProductCategoryT = VariobelProductCategoryT | PorezProductCategoryT;
