import { Box, Button, Dialog, Grid, Typography } from '@mui/material'
import React from 'react'

interface Props {
  files: string[];
  openDialog: boolean;
  onCloseDialog: () => void;
};

const OrderFiles = (props: Props) => {
  const { files, openDialog, onCloseDialog } = props;

  return (
    <Dialog
      open={openDialog}
      onClose={onCloseDialog}
      maxWidth="sm"
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          overflowX: "hidden",
        },
      }}
    >
      <Box p={6}>
        <Box mb={2}>
          <Typography sx={{ mb: 4, fontWeight: 700, fontSize: "1rem" }}>
            Chipboard Files
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            gap: 2,
          }}
        >
          <Grid container spacing={4} sx={{ justifyContent: "center" }}>
            {files.map((file, ind) => (
              <Grid item>
                <Box key={file} sx={{ width: 200 }}>
                  <img
                    src={file}
                    alt={"attachement" + ind}
                    style={{
                      width: "auto",
                      maxWidth: "100%",
                    }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>

          <Button
            type="submit"
            variant="contained"
            size="small"
            onClick={onCloseDialog}
            sx={{
              borderRadius: "100px",
            }}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default OrderFiles