import { Box, Button, Dialog, ListItem, Typography } from '@mui/material'
import { Note } from 'mdi-material-ui';
import React from 'react'
import { StyledRenderList } from './styled-render-list';

interface Props {
  notes: string[];
  openDialog: boolean;
  onCloseDialog: () => void;
};

const NoteList = ({ note }: { note: string }) => {
  return (
    <ListItem key={note}>
      <div className="file-details">
        <div className="file-preview">
          <Note />
        </div>
        <div className="file-detail">
          <Typography className="file-name">{note}</Typography>
        </div>
      </div>
    </ListItem>
  );
};

const OrderNotes = (props: Props) => {
  const { notes, openDialog, onCloseDialog } = props;

  return (
    <Dialog
      open={openDialog}
      onClose={onCloseDialog}
      maxWidth="sm"
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          overflowX: "hidden"
        },
      }}
    >
      <Box p={6}>
        <Box mb={2}>
          <Typography sx={{ mb: 2.5, fontWeight: 700, fontSize: "1rem" }}>
            Chipboard Notes
          </Typography>
        </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              gap: 2,
            }}
          >
            <StyledRenderList>
              {
                notes.map((note) => (
                  <NoteList note={note} />
                ))
              }
            </StyledRenderList>

            <Button
              type="submit"
              variant="contained"
              size="small"
              onClick={onCloseDialog}
              sx={{
                borderRadius: "100px",
              }}
            >
              Close
            </Button>
          </Box>
      </Box>
    </Dialog>
  );
}

export default OrderNotes