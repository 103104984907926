import { List, styled } from "@mui/material";

export const StyledRenderList = styled(List)(({ theme }) => ({
  padding: 0,
  marginTop: theme.spacing(3.25),
  width: "100%",

  "& .MuiListItem-root": {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2.5, 2.4, 2.5, 6),
    border: `1px solid ${theme.palette.mode === "light" ? "rgba(93, 89, 98, 0.14)" : "rgba(247, 244, 254, 0.14)"}`,
    flexWrap: "wrap",
    gap: theme.spacing(1),
    "& .file-details": {
      display: "flex",
      flex: 1,
      alignItems: "center",
      width: "100%"
    },
    "& .file-detail": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap"
    },
    "& .file-preview": {
      display: "flex",
      marginRight: theme.spacing(3.75),
      "& svg": {
        fontSize: "2rem"
      }
    },
    "& img": {
      width: 38,
      height: 38,
      padding: theme.spacing(0.75),
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.mode === "light" ? "rgba(93, 89, 98, 0.14)" : "rgba(247, 244, 254, 0.14)"}`
    },
    "& .file-name": {
      fontWeight: 600,
      // width: "50%",
      // whiteSpace: "nowrap",
      // overflow: "hidden",
      // textOverflow: "ellipsis"
    },
    "& + .MuiListItem-root": {
      marginTop: theme.spacing(3.5)
    }
  },
  "& + .buttons": {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(6.25),
    "& > :first-of-type": {
      marginRight: theme.spacing(3.5)
    }
  }
}));
